/*
 * Copyright 2015 brutusin.org
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
 * @author Ignacio del Valle Alles idelvall@brutusin.org
 */
.add-pattern-div{
    margin-top: 6px;
}
.loading-layer{
    position: absolute;
    top:0px;
    left:0px;
    z-index : 10;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: white;
}
.loading-icon{
    position: absolute;
    top:14px;
    left:50%;
    z-index : 11;
}
.loading-icon-select{
    position: absolute;
    top:14px;
    left:50%;
    z-index : 11;
}
.loading-icon-checkbox{
    position: absolute;
    top:7px;
    left:3px;
    z-index : 11;
}
.glyphicon-refresh-animate {
    animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}
form.brutusin-form table, form.brutusin-form input, form.brutusin-form select, form.brutusin-form textarea{
    width: 100% !important;
    min-width: 80px;
}
form.brutusin-form input[type=checkbox]{
    width: auto !important;
    min-width: auto !important;
}
form.brutusin-form  textarea{
    height: 8em;
}
form.brutusin-form table table{
    border-left:  solid 1px;
    border-color: lightgray;
    margin: 4px;   
}
form.brutusin-form td {
    vertical-align: top;
    padding: 4px;
    white-space: nowrap;
}
form.brutusin-form td.prop-name {
    text-align: right;
}
form.brutusin-form td.add-prop-name table {
    text-align: right;
    border: none;
}
form.brutusin-form td.add-prop-name table td {
    vertical-align: middle;
}
form.brutusin-form td.prop-value {
    width: 100%;   
    text-align: left;
}
form.brutusin-form td.item-index{
    font-size: 0.8em;
    color: lightgray;
    width: 25px;
    text-align: right;
}
form.brutusin-form td.item-action{
    width: 30px;
}
form.brutusin-form .error {
    border-color: red;
}
form.brutusin-form .error-message {
    color: red;
}
